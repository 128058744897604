<template>
    <div>
        <v-skeleton-loader type="list-item-three-line" style="height:100%;" :loading="!loaded" transition="fade-transition">
            <v-card flat>
                <v-card-title>
                    Residents
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="newResident" color="primary" style="text-transform:none">
                        <v-icon left>mdi-plus</v-icon>
                        Add new
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    These are all the residents using the system inside your community
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
                            <v-text-field v-model="filterResidents" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="residents" :search="filterResidents" :items-per-page="25">
                        <template v-slot:item.avatarSrc="{ item }">
                            <v-avatar size="35" color="teal">
                                <img :src="item.avatarSrc" v-if="item.avatarSrc != null && item.avatarSrc.length > 0">
                                <span class="white--text" v-else>{{ item.firstName[0].toUpperCase() + item.lastName[0].toUpperCase() }}</span>
                            </v-avatar>
                        </template>
                        <template v-slot:item.phone="{ item }">
                            {{ formatPhoneNum(item.phone) }}
                        </template>
                        <template v-slot:item.petOwner="{ item }">
                            {{ item.petOwner ? 'Yes' : 'No' }}
                        </template>
                        <template v-slot:item.more="{ item }">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">mdi-dots-vertical</v-icon>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="editResident(item)" dense>
                                        <v-list-item-content>
                                            Edit
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item @click="showAccount(item)" dense>
                                        <v-list-item-content>
                                            Account
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-icon>mdi-currency-usd</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-skeleton-loader>

        <resident ref="resident" :dialog="true" :dialogTitle="residentDialogTitle" :dialogSubtitle="residentDialogSubtitle" :dialogOpen="residentsDialog" 
                    @cancel="residentsDialog = false" @saved="addResident2Table" @update="updateResident"></resident>
        <resident-account ref="residentPayments" :dialog="true" :dialogOpen="residentPaymentsDialog" 
                    @cancel="residentPaymentsDialog = false"></resident-account>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import Resident from '@/components/Resident.vue';
import ResidentAccount from '@/components/ResidentAccount.vue';

export default {
    data: () => ({
        headers: [
            { text: 'Avatar', value: 'avatarSrc', sortable: false },
            { text: 'Name', value: 'fullName', sortable: true },
            { text: 'Phone', value: 'phone', sortable: true },
            { text: 'Email', value: 'email', sortable: true },
            { text: 'Unit Type', value: 'buildingType', sortable: true },
            { text: 'Unit', value: 'unitNumber', sortable: true },
            { text: 'Pet Owner', value: 'petOwner', sortable: true },
            // { text: 'Active', values: 'active', sortable: true },
            { text: 'Actions', value: 'more', sortable: false },
        ],
        residents: [],
        loaded: false,
        filterResidents: '',
        residentsDialog: false,
        residentDialogTitle: 'New Resident',
        residentDialogSubtitle: 'Add new resident to your community',
        residentPaymentsDialog: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.loaded = false;
                let res = await api.get('/admin/residents');
                this.loaded = true;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }
                
                this.residents = res.data.data;
            }
            catch(error) {
                this.loaded = true;
                this.mxShowMessage( error, 'error' );
            }
        },
        addResident2Table(resident) {
            let ix = this.residents.findIndex(r => {
                return r.id == resident.id;
            });

            if( ix > -1 ) {
                this.resident[ix] = resident;
                return; // is an update
            }

            this.residents.push(resident);
        },
        updateResident(resident) {
            let found = this.residents.find(r => {
                return r.id == resident.id;
            });
            
            if( found == undefined || found == null ) 
                return; // is not an update

            found.firstName = resident.firstName;
            found.lastName = resident.lastName;
            found.fullName = resident.fullName;
            found.phone = resident.phone;
            found.email = resident.email;
            found.buildingTypeId = resident.buildingTypeId;
            found.buildingType = resident.buildingType;
            found.unitNumber = resident.unitNumber;
            found.petOwner = resident.petOwner;
            found.locations = resident.locations;
        },
        newResident() {
            this.residentDialogTitle = 'New Resident';
            this.residentDialogSubtitle = 'Add new resident to your community';

            this.residentsDialog = true;
        },
        editResident(resident) {
            this.residentDialogTitle = 'Edit Resident';
            this.residentDialogSubtitle = 'Edit the selected resident information';

            this.$refs.resident.load( resident );
            this.residentsDialog = true;
        },
        showAccount(resident) {
            this.$refs.residentPayments.load( resident );
            this.residentPaymentsDialog = true;
        },
    },
    components: {
        Resident, ResidentAccount
    }
}
</script>

<style scoped>

</style>