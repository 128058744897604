<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="500" max-width="800" persistent>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-row v-if="summary != null">
                            <v-col xs="6" sm="6" md="4" lg="4" xl="4" class="mx-0">
                                <v-skeleton-loader type="card-heading" style="height:100%;" :loading="!loaded" transition="fade-transition">
                                    <div>
                                        <div :class="'headline text-center ' + balanceColor">{{ formatMoney(summary.balance) }}</div>
                                        <div class="text-center">Balance</div>
                                    </div>
                                </v-skeleton-loader>
                            </v-col>
                            <v-col xs="6" sm="6" md="4" lg="4" xl="4" class="mx-0">
                                <v-skeleton-loader type="card-heading" style="height:100%;" :loading="!loaded" transition="fade-transition">
                                    <div>
                                        <div :class="'headline text-center ' + balanceColor">{{ summary.paymentsBehind == undefined ? 0 : summary.paymentsBehind }}</div>
                                        <div class="text-center">Payments Behind</div>
                                    </div>
                                </v-skeleton-loader>
                            </v-col>
                            <v-col xs="6" sm="6" md="4" lg="4" xl="4" class="mx-0">
                                <v-skeleton-loader type="card-heading" style="height:100%;" :loading="!loaded" transition="fade-transition">
                                    <div>
                                        <div class="headline text-center">{{ summary.nextDueDateFormatted == undefined ? 'Not Available' : summary.nextDueDateFormatted }}</div>
                                        <div class="text-center">Next Due Date</div>
                                    </div>
                                </v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider class="my-3"></v-divider>
                                <span class="subtitle-1">Latest Payments</span>
                            </v-col>
                        </v-row>
                        <v-skeleton-loader type="table" style="height:100%;" :loading="!loaded" transition="fade-transition">
                            <v-data-table :headers="headers" :items="payments" :items-per-page="12" hide-default-footer dense>
                                <template v-slot:item.paymentDate="{ item }">
                                    <span class="body-2">{{ formatDate(item.paymentDate,'YYYY-MM-DD @ hh:mm a') }}</span>
                                </template>
                                <template v-slot:item.paymentMethod="{ item }">
                                    <span class="body-2">{{ item.paymentMethod }}</span>
                                </template>
                                <template v-slot:item.paidAmount="{ item }">
                                    <span class="body-2">{{ formatMoney(item.paidAmount) }}</span>
                                </template>
                                <template v-slot:item.receiptUrl="{ item }">
                                    <v-btn text color="primary" :href="item.meta['receiptUrl']" target="_blank" v-if="item.meta['receiptUrl'].length > 0">View</v-btn>
                                    <span v-else>--</span>
                                </template>
                            </v-data-table>
                        </v-skeleton-loader>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn text @click="openPaymentDialog"><v-icon>mdi-plus</v-icon> New Payment</v-btn> -->
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-card-text class="ma-0 pa-0">
                <v-row v-if="summary != null">
                    <v-col xs="6" sm="6" md="4" lg="4" xl="4" class="mx-0">
                        <v-skeleton-loader type="card-heading" style="height:100%;" :loading="!loaded" transition="fade-transition">
                            <div>
                                <div :class="'headline text-center ' + balanceColor">{{ formatMoney(summary.balance) }}</div>
                                <div class="text-center">Balance</div>
                            </div>
                        </v-skeleton-loader>
                    </v-col>
                    <v-col xs="6" sm="6" md="4" lg="4" xl="4" class="mx-0">
                        <v-skeleton-loader type="card-heading" style="height:100%;" :loading="!loaded" transition="fade-transition">
                            <div>
                                <div :class="'headline text-center ' + balanceColor">{{ summary.paymentsBehind }}</div>
                                <div class="text-center">Payments Behind</div>
                            </div>
                        </v-skeleton-loader>
                    </v-col>
                    <v-col xs="6" sm="6" md="4" lg="4" xl="4" class="mx-0">
                        <v-skeleton-loader type="card-heading" style="height:100%;" :loading="!loaded" transition="fade-transition">
                            <div>
                                <div class="headline text-center">{{ summary.nextDueDateFormatted }}</div>
                                <div class="text-center">Next Due Date</div>
                            </div>
                        </v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-divider class="my-3"></v-divider>
                        <span class="subtitle-1">Latest Payments</span>
                    </v-col>
                </v-row>
                <v-skeleton-loader type="table" style="height:100%;" :loading="!loaded" transition="fade-transition">
                    <v-data-table :headers="headers" :items="payments" :items-per-page="12" hide-default-footer dense>
                        <template v-slot:item.paymentDate="{ item }">
                            <span class="body-2">{{ formatDate(item.paymentDate,'YYYY-MM-DD @ hh:mm a') }}</span>
                        </template>
                        <template v-slot:item.paymentMethod="{ item }">
                            <span class="body-2">{{ item.paymentMethod }}</span>
                        </template>
                        <template v-slot:item.paidAmount="{ item }">
                            <span class="body-2">{{ formatMoney(item.paidAmount) }}</span>
                        </template>
                        <template v-slot:item.receiptUrl="{ item }">
                            <v-btn text color="primary" :href="item.receiptUrl" v-if="item.receiptUrl.length > 0" target="_blank">View</v-btn>
                            <span v-else>--</span>
                        </template>
                    </v-data-table>
                </v-skeleton-loader>
            </v-card-text>
            <v-card-actions>
                <!-- <v-btn text @click="openPaymentDialog"><v-icon>mdi-plus</v-icon> New Payment</v-btn> -->
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancel</v-btn>
            </v-card-actions>
        </v-card>

        <new-resident-payment ref="residentPayment" :dialog="true" :dialogOpen="newPaymentDialog" @cancel="newPaymentDialog = false"></new-resident-payment>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import NewResidentPayment from '@/components/NewResidentPayment.vue';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'Resident Account'
        },
        dialogSubtitle: {
            type: String,
            default: 'View account summary and latest payments'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        headers: [
            { text: 'Date', value: 'paymentDate', sortable: true, width: '23%' },
            { text: 'Method', value: 'paymentMethod', sortable: true, width: '30%' },
            // { text: 'Confirmation', value: 'paymentConfirmationNumber', sortable: true, width: '20%' },
            { text: 'Amount', value: 'paidAmount', sortable: true, width: '17%' },
            { text: 'Receipt', value: 'receiptUrl', sortable: true, width: '10%' },
        ],
        summary: null,
        payments: [],
        availablePayments: [],
        local: {
            id: 0,
            firstName: '',
            lastName: '',
            fullNae: '',
            email: '',
            phone: '',
            unitType: '',
            unitNumber: '',
        },
        newPaymentDialog: false,
        loaded: false,
    }),
    computed: {
        balanceColor() {
            if( this.summary == null )
                return '';

            if( this.summary.paymentsBehind > 3 )
                return 'error--text';
            else if( this.summary.paymentsBehind > 0 && this.summary.paymentsBehind <= 3 )
                return 'warning--text';

            return '';
        }
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD');
        },
        cancel() {
            this.$emit('cancel');
            this.local = {
                id: 0,
                firstName: '',
                lastName: '',
                fullName: '',
                email: '',
                phone: '',
                unitType: '',
                unitNumber: '',
            };
            
            this.showAlert = false;
        },
        isValid() {
            let errorFound = false;
            

            return !errorFound;
        },
        async load(r) {
            let api = new API();

            try {
                this.loaded = false;
                this.local = {
                    id: r.id,
                    firstName: r.firstName,
                    lastName: r.lastName,
                    fullName: r.fullName,
                    email: r.email,
                    phone: r.phone,
                    unitType: r.buildingType,
                    unitNumber: r.unitNumber,
                };

                let res = await api.get(`/admin/resident/account/${this.local.id}`);
                this.loaded = true;

                if( !res.data.status && res.data.error ) {
                    console.log('ERROR: ' + res.data.message );
                    return;
                }
                
                this.summary = res.data.data.summary;
                this.payments = res.data.data.lastPayments;
                this.availablePayments = res.data.data.paymentsAvailable;

                for( let i = 0; i < this.payments.length; i++) {
                    this.payments[i].meta = JSON.parse( this.payments[i].meta );
                }
            }
            catch(error) {
                this.loaded = true;
                console.log(error);
            }
        },
        save() {
            if( !this.isValid() )
                return;

            let tmpLocal = JSON.parse( JSON.stringify( this.local ) );
            tmpLocal.phone = tmpLocal.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')

            this.saving = true;
            let axiosOptions = {
                method: this.local.id == 0 ? 'POST' : 'PUT',
                url: '/admin/resident',
                data: { 'form': tmpLocal },
                json: true
            };

            this.$http(axiosOptions)
            .then( response => { 
                this.saving = false;
                if( !response.data.status && response.data.error ) {
                    this.alertMessage = response.data.message;
                    this.alertColor = 'error';
                    this.showAlert = true;
                    return;
                }

                if( !response.data.status && !response.data.error ) {
                    this.alertMessage = response.data.message;
                    this.alertColor = 'warning';
                    this.showAlert = true;
                    return;
                }

                // is an update
                if( this.local.id != 0 ) {
                    this.$emit('update', response.data.data);
                    this.local.id = response.data.data.residentId;
                    this.cancel();
                    return;
                }

                this.$emit('saved', response.data.data);
                this.local.id = response.data.data.residentId;

                // show confirmation message
                
                this.cancel();
            })
            .catch(error => {
                this.saving = false;
                alert(error);
            });
        },
        openPaymentDialog() {
            this.$refs.residentPayment.load(this.local.id, this.availablePayments);
            this.newPaymentDialog = true;
        },
        closePaymentDialog() {
            this.newPaymentDialog = false;
        }
    },
    components: {
        NewResidentPayment
    }
}
</script>

<style scoped>

</style>