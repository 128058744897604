var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticStyle:{"height":"100%"},attrs:{"type":"list-item-three-line","loading":!_vm.loaded,"transition":"fade-transition"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Residents "),_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":"","color":"primary"},on:{"click":_vm.newResident}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add new ")],1)],1),_c('v-card-subtitle',[_vm._v(" These are all the residents using the system inside your community ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"6","xl":"6","offset-lg":"6","offset-xl":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.filterResidents),callback:function ($$v) {_vm.filterResidents=$$v},expression:"filterResidents"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.residents,"search":_vm.filterResidents,"items-per-page":25},scopedSlots:_vm._u([{key:"item.avatarSrc",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35","color":"teal"}},[(item.avatarSrc != null && item.avatarSrc.length > 0)?_c('img',{attrs:{"src":item.avatarSrc}}):_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.firstName[0].toUpperCase() + item.lastName[0].toUpperCase()))])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNum(item.phone))+" ")]}},{key:"item.petOwner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.petOwner ? 'Yes' : 'No')+" ")]}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-dots-vertical")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.editResident(item)}}},[_c('v-list-item-content',[_vm._v(" Edit ")]),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.showAccount(item)}}},[_c('v-list-item-content',[_vm._v(" Account ")]),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)],1)],1)],1)]}}])})],1)],1)],1),_c('resident',{ref:"resident",attrs:{"dialog":true,"dialogTitle":_vm.residentDialogTitle,"dialogSubtitle":_vm.residentDialogSubtitle,"dialogOpen":_vm.residentsDialog},on:{"cancel":function($event){_vm.residentsDialog = false},"saved":_vm.addResident2Table,"update":_vm.updateResident}}),_c('resident-account',{ref:"residentPayments",attrs:{"dialog":true,"dialogOpen":_vm.residentPaymentsDialog},on:{"cancel":function($event){_vm.residentPaymentsDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }